import Plyr from 'plyr';
import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'bulma-pageloader';
import { initializeComponent, debounce, validateEmail } from '../helpers';

const MOBILE_BREAKPOINT = 768;
const INFOBOX_MAX_CLOSE = 3;
const VIMEO_HASH_REGEX = /.*vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(?:\d+)(?:$|\/|\?)((?:.*?h=)?)([\d|a-z]+)/;

Rails.start();
ActiveStorage.start();

document.addEventListener('DOMContentLoaded', () => {
  // Toggle burger and menu state.
  initializeComponent('.navbar-burger', ([burger]) => {
    const mobileMenu = document.querySelector('aside.menu');

    burger.addEventListener('click', () => {
      burger.classList.toggle('is-active');
      mobileMenu.classList.toggle('is-active');
    });
  });

  // Initialize popovers
  initializeComponent('.popover-trigger a', (popoverLinks) => {
    popoverLinks.forEach((link) => {
      link.addEventListener('click', (event) => {
        event.preventDefault();
        event.stopPropagation();

        const popover = event.target.closest('.popover');
        const activePopover = document.querySelector('.popover.is-popover-active');

        if (activePopover && activePopover !== popover) {
          activePopover.classList.remove('is-popover-active');
        }
        popover.classList.toggle('is-popover-active');
      });
    });
  });

  // Click outside closes popover
  document.addEventListener('click', ({ target }) => {
    if (target.classList.contains('popover-backdrop') || !target.closest('.popover')) {
      const activePopover = document.querySelector('.popover.is-popover-active');

      if (activePopover) {
        activePopover.classList.remove('is-popover-active');
      }
    }
  });

  // Initialize purchase side panel
  initializeComponent('.course-landing .landing-aside', ([landingAside]) => {
    const landingPrice = landingAside.querySelector('.info-landing-price');

    const ceusCard = document.querySelector('.course-landing .ceus-card');
    const purchaseForm = document.querySelector('.course-landing .purchase-form');

    const onScroll = () => {
      const { bottom: ceusCardBottom } = ceusCard.getBoundingClientRect();
      const { top: formTop } = purchaseForm.getBoundingClientRect();

      const rectLandingPrice = landingPrice.getBoundingClientRect();

      const { innerHeight } = window;

      if (window.innerWidth <= MOBILE_BREAKPOINT) {
        landingAside.classList.toggle('detached', rectLandingPrice.top <= innerHeight - rectLandingPrice.height);
        landingAside.classList.toggle('hidden', formTop < innerHeight);
      } else {
        landingAside.classList.toggle('top-indented', ceusCardBottom <= rectLandingPrice.top);
        landingAside.classList.toggle('hidden', formTop < innerHeight);

        landingAside.style.transform = ceusCardBottom <= 0
          ? `translate3d(0, 0, 1px) translateY(${-ceusCardBottom}px)`
          : 'none';
      }
    };

    window.addEventListener('scroll', onScroll);

    window.addEventListener('resize', () => {
      if (window.innerWidth <= MOBILE_BREAKPOINT) {
        landingAside.classList.remove('top-indented');
        landingAside.classList.remove('hidden');

        landingAside.style.transform = 'none';
      } else {
        landingAside.classList.remove('detached');
        landingAside.classList.remove('hidden');
      }

      onScroll();
    });

    // handle click on the 'enroll' button
    const purchaseButton = landingAside.querySelector('button');

    purchaseButton.addEventListener('click', () => {
      window.scrollTo({
        left: 0,
        top: purchaseForm.offsetTop - 20,
        behavior: 'smooth',
      });
    });
  });

  // Initialize purchase form
  initializeComponent('.course-landing .purchase-form', ([purchaseForm]) => {
    const courseIncludesCard = purchaseForm.querySelector('.course-includes');
    const purchaseModal = purchaseForm.querySelector('.purchase-modal');
    const emailInput = purchaseModal.querySelector('input[type="email"]');
    const purchaseBundled = purchaseModal.querySelector('input[name="bundled"]');
    const purchaseSubmit = purchaseModal.querySelector('input[type="submit"]');
    const purchaseHeader = purchaseModal.querySelector('h1');
    const purchaseButtons = [...purchaseForm.querySelectorAll('.purchase-button')];

    // Validate email
    if (emailInput) {
      emailInput.addEventListener('input', debounce(async (event) => {
        const { value } = event.target;
        const isValid = validateEmail(value);

        purchaseSubmit.disabled = !isValid;
      }));
    }

    // Check height
    if (purchaseForm.clientHeight < courseIncludesCard.clientHeight) {
      purchaseForm.style.height = `${courseIncludesCard.clientHeight}px`;
    }

    // Handle purchase modal
    const togglePurchaseModal = (resetBundled = false) => {
      if (resetBundled && purchaseBundled) purchaseBundled.value = 0;
      purchaseSubmit.disabled = !validateEmail(emailInput.value);
      purchaseModal.classList.toggle('is-hidden');
    };
    const closePurchaseModal = () => togglePurchaseModal(true);

    // Handle close purchase modal
    purchaseModal.querySelector('.modal-background').addEventListener('click', closePurchaseModal);
    purchaseModal.querySelector('button.modal-close').addEventListener('click', closePurchaseModal);

    // Handle open purchase modal
    purchaseButtons.forEach((button) => {
      button.addEventListener('click', () => {
        const { enroll, bundled, price } = button.dataset;

        if (purchaseBundled) purchaseBundled.value = bundled;

        if (enroll === 'true') {
          purchaseHeader.querySelector('.non-bundle').classList.toggle('is-hidden', Number(bundled));
          purchaseHeader.querySelector('.bundle').classList.toggle('is-hidden', !Number(bundled));
          purchaseHeader.querySelector('.purchasing-price').innerHTML = price;
          togglePurchaseModal();
        } else {
          purchaseSubmit.disabled = false;
          purchaseSubmit.click();
        }
      });
    });
  });

  // Initialize player on landing page
  initializeComponent('.course-landing .plyr__video-embedid', ([playerDiv]) => {
    const matches = playerDiv.dataset.url.match(VIMEO_HASH_REGEX);
    const hKey = matches ? matches[4] : '';

    new Plyr(playerDiv, {
      vimeo: { h: hKey },
    });
  });

  /* eslint-disable */
  initializeComponent('.payment-success-page', ([$container]) => {
    const $loader = $container.querySelector('.pageloader');
    const $message = $loader.querySelector('.title');
    const start = +new Date();

    const stopLoading = () => $loader.classList.remove('is-active');
    const setMessage = (message) => $message.innerHTML = message;

    const {
      endpoint,
      timeout,
      interval,
      userEmail,
      supportEmail,
    } = window.IOC;

    const timeoutMessage = `
      The payment processing takes too long. Please contact <a target="_blank" class="is-dark is-underlined" href="mailto:${supportEmail}">${supportEmail}</a>.`;
    const errorMessage = `
      An unrecoverable error occured. Please contact <a target="_blank" class="is-dark is-underlined" href="mailto:${supportEmail}">${supportEmail}</a>.`;

    if (!endpoint) return stopLoading();

    const poll = () => {
      fetch(`${endpoint}?email=${encodeURIComponent(userEmail)}`, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(({ data: completed }) => {
        const duration = new Date() - start;

        if (duration >= timeout) return setMessage(timeoutMessage);
        if (completed) return stopLoading();

        setTimeout(poll, interval);
      })
      .catch(() => setMessage(errorMessage));
    };

    poll();
  });
  /* eslint-enable */

  initializeComponent('.course-dashboard .box', ([infoBox]) => {
    const closeCount = Number(localStorage.getItem('infoBox_closeCount')) || 0;

    if (closeCount < INFOBOX_MAX_CLOSE) {
      infoBox.classList.remove('is-hidden');
    }

    const closeBtn = infoBox.querySelector('button');

    closeBtn.addEventListener('click', () => {
      infoBox.classList.add('is-hidden');
      localStorage.setItem('infoBox_closeCount', closeCount + 1);
    });
  });
});
