export const initializeComponent = (selector, callback) => {
  const elements = document.querySelectorAll(selector);

  if (!elements || !elements.length || !callback) return;

  callback(elements);
};

export const waitForCondition = (condition, callback, interval) => {
  setTimeout(() => {
    if (condition()) {
      callback();
    } else {
      waitForCondition(condition, callback, interval);
    }
  }, interval);
};

export const debounce = (callback, timeout = 300) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, timeout);
  };
};

export const validateEmail = (email) => {
  const emailRegexp = /\S+@\S+\.\S+/;

  return emailRegexp.test(email);
};

export const validateThreshold = (value) => Math.max(Math.min(parseInt(value, 10), 100), 0);

export const removeFormattingOnPaste = (event) => {
  event.preventDefault();

  const paste = (event.clipboardData || window.clipboardData).getData('text');
  const selection = window.getSelection();

  if (!selection.rangeCount) return;

  selection.deleteFromDocument();
  selection.getRangeAt(0).insertNode(document.createTextNode(paste.trim()));
  selection.collapseToEnd();
};
